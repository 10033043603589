module.exports = {
  BASE_URL: "https://masslivestream.com/",
  ADMIN_API_KEY: "DamagedChickensEducationExpert",
  ITEM_TYPES: {
    CLIP: "clip",
    RECORDING: "recording",
    STREAM: "stream"
  },
  TOGGLE_FIELD_TYPES: {
    PUBLIC: "public",
    SAVED: "keep"
  },
  COPY_TO_CLIPBOARD_TYPES: {
    ICON: "ICON",
    BUTTON: "BUTTON"
  },
  STATS_TYPES: {
    STREAMS_BY_STREAM: "0",
    STREAMS_BY_EVENT: "3",
    RECORDINGS: "1",
    CLIPS: "2"
  },
  DATE_TYPES: {
    START_DT: "START_DT",
    END_DT: "END_DT"
  }
}
