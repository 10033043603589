import { TABLE_ICONS, TABLE_OPTIONS } from "../configs/SharedTableConfig"
import MaterialTable from "material-table"

export const CustomTable = ({ tableColumns, tableData }) => (
  <MaterialTable
    options={TABLE_OPTIONS}
    icons={TABLE_ICONS}
    columns={tableColumns}
    data={tableData}
    title=""
  />
)
