import { axiosWithAuth } from "../helpers/AxiosInterceptor"
import { BASE_URL } from "../helpers/Constants"
import { getUserEmail } from "../helpers/Helpers"
import { RECURRING_TYPE, SET_TYPE, SINGLE_TYPE } from "./ScheduleEventModal"
import { useState } from "react"
import { Button, Header, Modal } from "semantic-ui-react"

export function DeleteItemModal({ style, type, id, title, callback }) {
  const [showModal, setShowModal] = useState(false)
  const [statusMessage, setStatusMessage] = useState(null)
  const [deleteInProgress, setDeleteInProgress] = useState(false)
  const [deleteSuccessful, setDeleteSuccessful] = useState(false)

  const buttonLabel =
    type === SINGLE_TYPE || type === SET_TYPE
      ? "Event"
      : type === RECURRING_TYPE
      ? "Series"
      : "Delete"

  const typeLabel =
    type === SINGLE_TYPE || type === SET_TYPE
      ? "single event"
      : type === RECURRING_TYPE
      ? "event series"
      : type

  function deleteItem() {
    console.log(`Entered deleteItem()`)

    reset()
    setDeleteInProgress(true)
    const url = `${BASE_URL}admin/api/delete-item`
    axiosWithAuth
      .post(url, {
        validateSiteForType: true,
        user: getUserEmail(),
        type,
        id
      })
      .then(() => {
        setStatusMessage(
          <div style={{ marginBottom: "10px", textAlign: "center", color: "green" }}>
            Item deleted successfully.
          </div>
        )
        setDeleteInProgress(false)
        setDeleteSuccessful(true)
        callback({
          success: true,
          type,
          id
        })
      })
      .catch(() => {
        setStatusMessage(
          <div className="error" style={{ marginTop: "15px" }}>
            An error occurred, please try again.
          </div>
        )
        setDeleteInProgress(false)
        callback({
          success: false,
          type,
          id
        })
      })
  }

  function reset() {
    setStatusMessage(null)
    setDeleteInProgress(false)
    setDeleteSuccessful(false)
  }

  function resetAndClose() {
    reset()
    setShowModal(false)
  }

  return (
    <Modal
      showModal={showModal}
      trigger={
        <Button color="red" style={style}>
          {buttonLabel}
        </Button>
      }
      onClose={resetAndClose}
      onOpen={() => setShowModal(true)}
      open={showModal}
      style={{ width: "500px", maxWidth: "100%" }}>
      <Header icon="delete" content={`Delete ${typeLabel}`} />
      <Modal.Content>
        {!deleteSuccessful && (
          <div>
            <p>
              Are you sure you want to delete <i>{title}</i>? This operation cannot be undone.
            </p>
            <p>Click "Confirm" below to process, or "Close" to cancel.</p>
          </div>
        )}
        {statusMessage}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={resetAndClose}>Close</Button>
        <Button
          disabled={deleteInProgress || deleteSuccessful}
          loading={deleteInProgress}
          className="blue"
          onClick={deleteItem}>
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
