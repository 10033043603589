import { useState, useEffect } from "react"
import { Container, Header, Icon, Loader } from "semantic-ui-react"

export function LoadingError() {
  const [inDelay, setInDelay] = useState(true)

  useEffect(
    () =>
      setTimeout(function () {
        setInDelay(false)
      }, 500),
    []
  )

  return inDelay ? (
    <Loader active inline="centered" />
  ) : (
    <Container textAlign={"center"}>
      <Icon name="exclamation" size="massive" />
      <Header as="h1">Error loading data</Header>
      <Header as="h2">Please try reloading the page</Header>
    </Container>
  )
}
