import { ITEM_TYPES } from "../helpers/Constants"
import { buildItemURL } from "../helpers/Helpers"
import { Button } from "semantic-ui-react"

export function ViewItemButton({ type, slug, id, isPrivate, itemKey }) {
  const privateClip = type === ITEM_TYPES.CLIP && isPrivate
  const url = buildItemURL(type, slug, id, isPrivate, itemKey)
  return (
    !privateClip && (
      <a href={url} target="_blank" rel="noreferrer">
        <Button>View</Button>
      </a>
    )
  )
}
