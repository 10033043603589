import { CopyToClipboardButton } from "../components/CopyToClipboardButton"
import { DeleteItemModal } from "../components/DeleteItemModal"
import { DownloadItemModal } from "../components/DownloadItemModal"
import { ToggleItemSwitch } from "../components/ToggleItemSwitch"
import { UpdateItemTitleModal } from "../components/UpdateItemTitleModal"
import { ViewItemButton } from "../components/ViewItemButton"
import { COPY_TO_CLIPBOARD_TYPES, ITEM_TYPES, TOGGLE_FIELD_TYPES } from "../helpers/Constants"
import { buildEmbedCode, buildItemURL, InfoBubble, userNotLimited } from "../helpers/Helpers"

export const buildClipsTableConfig = (
  siteID,
  siteSlug,
  updateTitleCallback,
  togglePublicCallback,
  deleteItemCallback
) => [
  {
    title: "Title",
    field: "cliptitle",
    render: (rowData) => (
      <div>
        <b>{rowData.cliptitle}</b>&nbsp;
        {userNotLimited() && (
          <UpdateItemTitleModal
            type={ITEM_TYPES.CLIP}
            id={rowData.clipid}
            currentTitle={rowData.cliptitle}
            callback={updateTitleCallback}
          />
        )}
      </div>
    )
  },
  {
    title: (
      <span>
        Date&nbsp;
        <InfoBubble
          size={"small"}
          message={"Date when the source recording for the clip took place."}
        />
      </span>
    ),
    field: "time"
  },
  {
    title: (
      <span>
        In Public Feed&nbsp;
        <InfoBubble
          size={"small"}
          message={
            <div>
              <p>Whether or not the clip is listed on your parish's public MassLivestream page.</p>
              <p>Clips can always be access via the admin dashboard and shared via permalink.</p>
            </div>
          }
        />
      </span>
    ),
    field: "ispublic",
    render: (rowData) => (
      <ToggleItemSwitch
        siteID={siteID}
        type={ITEM_TYPES.CLIP}
        fieldType={TOGGLE_FIELD_TYPES.PUBLIC}
        id={rowData.clipid}
        itemValue={rowData.ispublic}
        callback={togglePublicCallback}
      />
    )
  },
  {
    title: (
      <span>
        Audio Only&nbsp;
        <InfoBubble size={"small"} message={"Whether or not the clip is an audio MP3."} />
      </span>
    ),
    sortable: false,
    render: (rowData) => (rowData.url.includes(".mp3") ? "Yes" : "No")
  },
  {
    title: "",
    sortable: false,
    render: (rowData) =>
      rowData.clippresent ? (
        <ViewItemButton
          type={ITEM_TYPES.CLIP}
          slug={siteSlug}
          id={rowData.clipid}
          isPrivate={!rowData.ispublic}
        />
      ) : (
        <i>Processing clip...</i>
      )
  },
  {
    title: "",
    render: (rowData) =>
      rowData.clippresent ? (
        <CopyToClipboardButton
          type={COPY_TO_CLIPBOARD_TYPES.BUTTON}
          textToCopy={buildItemURL(
            ITEM_TYPES.CLIP,
            siteSlug,
            rowData.clipid,
            !rowData.ispublic,
            rowData.key
          )}
          hide={!rowData.ispublic}
        />
      ) : null
  },
  {
    title: "",
    render: (rowData) =>
      rowData.clippresent && rowData.ispublic ? (
        <CopyToClipboardButton
          type={COPY_TO_CLIPBOARD_TYPES.BUTTON}
          textToCopy={buildEmbedCode(ITEM_TYPES.CLIP, rowData.clipid)}
          typeOverride={"Embed Code"}
        />
      ) : null
  },
  {
    title: "",
    render: (rowData) =>
      rowData.clippresent ? <DownloadItemModal type={ITEM_TYPES.CLIP} url={rowData.url} /> : null
  },
  {
    title: "",
    render: (rowData) =>
      rowData.clippresent
        ? userNotLimited() && (
            <DeleteItemModal
              type={ITEM_TYPES.CLIP}
              id={rowData.clipid}
              title={rowData.cliptitle}
              callback={deleteItemCallback}
            />
          )
        : null
  }
]
