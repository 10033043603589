import { getUserData } from "./Helpers"

const axios = require("axios")

export const axiosWithAuth = axios.create()

axiosWithAuth.interceptors.request.use(
  async (config) => {
    const userData = getUserData()
    if (userData) {
      config.headers = {
        Authorization: `Bearer ${userData.jwt}`
      }
      return config
    } else {
      window.location.href = "/login"
    }
  },
  (error) => Promise.reject(error)
)

// Response interceptor for API calls
axiosWithAuth.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 401) {
      window.location.href = "/login"
    } else {
      return Promise.reject(error)
    }
  }
)
