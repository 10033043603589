import { LoadingError } from "../../components/LoadingError"
import { axiosWithAuth } from "../../helpers/AxiosInterceptor"
import { BASE_URL, DATE_TYPES, STATS_TYPES } from "../../helpers/Constants"
import { getUserEmail, logEvent } from "../../helpers/Helpers"
import React, { useState, useEffect } from "react"
import SemanticDatepicker from "react-semantic-ui-datepickers"
import { Header, Tab, Loader, Table } from "semantic-ui-react"

const DISPLAY_TYPE_MAPPING = {
  0: "Stream",
  1: "Recording",
  2: "Clip",
  3: "Event"
}

export default function StatsTab({ siteInfo }) {
  const [loadedType, setLoadedType] = useState(null)
  const [stats, setStats] = useState(null)
  const [statsLoaded, setStatsLoaded] = useState(false)
  const [statsLoadError, setStatsLoadError] = useState(false)
  const [breakdownStats, setBreakdownStats] = useState(null)
  const [breakdownStatsLoaded, setBreakdownStatsLoaded] = useState(false)
  const [breakdownStatsLoadError, setBreakdownStatsLoadError] = useState(false)
  const [startDt, setStartDt] = useState(getDateWithOffset(-7))
  const [endDt, setEndDt] = useState(getDateWithOffset(0))

  const tabPanes = [
    {
      menuItem: "Streams (by stream)",
      render: () => (
        <Tab.Pane attached={false}>{buildStatsTab(STATS_TYPES.STREAMS_BY_STREAM)}</Tab.Pane>
      )
    },
    {
      menuItem: "Streams (by event)",
      render: () => (
        <Tab.Pane attached={false}>{buildStatsTab(STATS_TYPES.STREAMS_BY_EVENT)}</Tab.Pane>
      )
    },
    {
      menuItem: "Recordings",
      render: () => <Tab.Pane attached={false}>{buildStatsTab(STATS_TYPES.RECORDINGS)}</Tab.Pane>
    },
    {
      menuItem: "Clips",
      render: () => <Tab.Pane attached={false}>{buildStatsTab(STATS_TYPES.CLIPS)}</Tab.Pane>
    }
  ]

  useEffect(() => logEvent("Dashboard_Tab_Accessed", { email: getUserEmail(), tab: "stats" }), [])

  function loadStats(type, providedStartDt = null, providedEndDt = null) {
    console.log(`Entering loadStats(${type})`)
    setStats([])
    setStatsLoaded(false)
    setStatsLoadError(false)

    if (type === STATS_TYPES.STREAMS_BY_STREAM) {
      loadBreakdownStats(providedStartDt, providedEndDt)
    }

    const url = `${BASE_URL}admin/api/stats/${siteInfo.siteid}/${type}?startDt=${
      providedStartDt || startDt
    }&endDt=${providedEndDt || endDt}`
    axiosWithAuth
      .get(url)
      .then((response) => {
        setStats(response.data)
        setStatsLoaded(true)
      })
      .catch(() => {
        setStatsLoaded(true)
        setStatsLoadError(true)
      })
  }

  function loadBreakdownStats(providedStartDt, providedEndDt) {
    console.log(`Entering loadBreakdownStats()`)
    setBreakdownStatsLoaded(false)
    setBreakdownStatsLoadError(false)

    const url = `${BASE_URL}admin/api/breakdown-stats/${siteInfo.siteid}?startDt=${
      providedStartDt || startDt
    }&endDt=${providedEndDt || endDt}`
    axiosWithAuth
      .get(url)
      .then((response) => {
        setBreakdownStats(response.data)
        setBreakdownStatsLoaded(true)
      })
      .catch(() => {
        setBreakdownStatsLoaded(true)
        setBreakdownStatsLoadError(true)
      })
  }

  function datePickerHandler(type, data, setter, getter) {
    const date = new Date(data.value)
    const dateString = formatDate(
      date.getFullYear().toString(),
      (date.getMonth() + 1).toString(),
      date.getDate().toString()
    )
    if (dateString !== getter) {
      const startDt = type === DATE_TYPES.START_DT ? dateString : null
      const endDt = type === DATE_TYPES.END_DT ? dateString : null
      setter(dateString)
      loadStats(loadedType, startDt, endDt)
    }
  }

  function formatDate(year, month, day) {
    return `${year}-${(month.length === 1 ? "0" : "") + month}-${
      (day.length === 1 ? "0" : "") + day
    }`
  }

  function getDateWithOffset(offset) {
    const date = new Date()
    date.setDate(date.getDate() + offset)
    const dateParts = date.toLocaleDateString().split("/")
    return formatDate(dateParts[2], dateParts[0], dateParts[1])
  }

  function formatDuration(duration) {
    const durationHours = duration.split(",")[0]
    const durationMinutes = duration.split(",")[1]
    let durationString = ""
    if (durationHours >= 1) {
      durationString += durationHours[0] === "0" ? durationHours.substring(1) : durationHours
      durationString += durationHours === 1 ? "hr " : "hrs "
    }
    if (durationMinutes >= 1) {
      durationString += durationMinutes[0] === "0" ? durationMinutes.substring(1) : durationMinutes
      durationString += durationMinutes === 1 ? "min " : "mins "
    }
    return durationString
  }

  const buildBreakdownTableRow = (item) => (
    <Table.Row key={`${item.item}:${item.itemdate}`}>
      <Table.Cell>{item.itemdate}</Table.Cell>
      <Table.Cell>{item.item}</Table.Cell>
      <Table.Cell>{item.views}</Table.Cell>
      <Table.Cell>{formatDuration(item.duration)}</Table.Cell>
    </Table.Row>
  )

  const buildStreamBreakdownStats = () => (
    <div style={{ marginTop: "30px" }}>
      {breakdownStatsLoadError ? (
        <LoadingError />
      ) : !breakdownStatsLoaded ? (
        <Loader active inline="centered" />
      ) : breakdownStats && breakdownStats.length > 0 ? (
        <div>
          <Header as="h3">Stream Stats Breakdown by Day</Header>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Stream</Table.HeaderCell>
                <Table.HeaderCell>Number of Views</Table.HeaderCell>
                <Table.HeaderCell>Total Watch Time</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{breakdownStats.map((item) => buildBreakdownTableRow(item))}</Table.Body>
          </Table>
        </div>
      ) : null}
    </div>
  )

  const buildTableRow = (item) => (
    <Table.Row key={item.itemid}>
      <Table.Cell>{item.item}</Table.Cell>
      {item.itemdate && <Table.Cell>{item.itemdate}</Table.Cell>}
      <Table.Cell>{item.views}</Table.Cell>
      <Table.Cell>{formatDuration(item.duration)}</Table.Cell>
      <Table.Cell>~{item.average}mins</Table.Cell>
    </Table.Row>
  )

  function buildStatsTab(type) {
    setLoadedType(type)
    if (type !== loadedType) {
      loadStats(type)
    }
    return statsLoadError ? (
      <LoadingError />
    ) : !statsLoaded ? (
      <Loader active inline="centered" />
    ) : stats && stats.length > 0 ? (
      <div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{DISPLAY_TYPE_MAPPING[type]} Title</Table.HeaderCell>
              {stats[0].itemdate && <Table.HeaderCell>Date</Table.HeaderCell>}
              <Table.HeaderCell>Number of View</Table.HeaderCell>
              <Table.HeaderCell>Total Watch Time</Table.HeaderCell>
              <Table.HeaderCell>Average Watch Time</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{stats.map((item) => buildTableRow(item))}</Table.Body>
        </Table>
        {type === STATS_TYPES.STREAMS_BY_STREAM && buildStreamBreakdownStats()}
        {(loadedType === STATS_TYPES.STREAMS_BY_EVENT || loadedType === STATS_TYPES.RECORDINGS) && (
          <div style={{ marginTop: "15px", textAlign: "center" }}>
            <i>Stats before November 2021 only available in the "Stream (by stream)" view.</i>
          </div>
        )}
        {loadedType === STATS_TYPES.CLIPS && (
          <div style={{ marginTop: "15px", textAlign: "center" }}>
            <i>Clip stats unreliable before February 2022.</i>
          </div>
        )}
      </div>
    ) : (
      <div style={{ textAlign: "center" }}>
        <Header as="h2">No Stats for This Type/Time Period</Header>Please try a different time
        period.
      </div>
    )
  }

  const buildDatepickerArea = () => (
    <div style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}>
      <SemanticDatepicker
        value={new Date(`${startDt}T00:00:00.000`)}
        onChange={(_, data) => datePickerHandler(DATE_TYPES.START_DT, data, setStartDt, startDt)}
        datePickerOnly={true}
        showToday={false}
        clearable={false}
      />
      <span className="datePickerTo">&nbsp;&nbsp;to&nbsp;&nbsp;</span>
      <SemanticDatepicker
        value={new Date(`${endDt}T00:00:00.000`)}
        onChange={(_, data) => datePickerHandler(DATE_TYPES.END_DT, data, setEndDt, endDt)}
        datePickerOnly={true}
        showToday={false}
        clearable={false}
      />
    </div>
  )

  return (
    <Tab.Pane attached={false}>
      <Header as="h1" textAlign={"center"}>
        Stats
      </Header>
      <div className="hrLargeBlue" />
      {buildDatepickerArea()}
      <Tab menu={{ secondary: true, pointing: false }} panes={tabPanes} />
    </Tab.Pane>
  )
}
