import { COPY_TO_CLIPBOARD_TYPES } from "../helpers/Constants"
import { CopyToClipboardButton } from "./CopyToClipboardButton"
import { useState } from "react"
import { Button, Header, Input, Modal } from "semantic-ui-react"

export function DownloadItemModal({ type, url }) {
  const [showModal, setShowModal] = useState(false)

  return (
    <Modal
      showModal={showModal}
      trigger={<Button>Download</Button>}
      onClose={() => setShowModal(false)}
      onOpen={() => setShowModal(true)}
      open={showModal}
      style={{ width: "500px", maxWidth: "100%" }}>
      <Header icon="download" content={`Download ${type}`} />
      <Modal.Content>
        <p>
          Click "Download" below to begin the download. This process may take a few minutes to
          complete, depending on the file size and your connection speed.
        </p>
        <p>
          Note: If the video opens instead of staring to download, right click the video and select
          "Save Video As".
        </p>
        <div style={{ marginTop: "15px" }}>
          Download Link:
          <Input style={{ width: "90%" }} value={url} readOnly={true} />
          <CopyToClipboardButton type={COPY_TO_CLIPBOARD_TYPES.ICON} textToCopy={url} />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setShowModal(false)}>Close</Button>
        <a href={url}>
          <Button className="blue">Download</Button>
        </a>
      </Modal.Actions>
    </Modal>
  )
}
