import { axiosWithAuth } from "../../helpers/AxiosInterceptor"
import { BASE_URL } from "../../helpers/Constants"
import {
  boolToYesNo,
  InfoBubble,
  getUserEmail,
  logEvent,
  userNotLimited
} from "../../helpers/Helpers"
import React, { useEffect, useState } from "react"
import {
  Tab,
  Header,
  Form,
  Button,
  TextArea,
  Input,
  Label,
  Accordion,
  Checkbox,
  Select,
  Icon
} from "semantic-ui-react"

const DAYS_TO_SHOW_RECORDING_OPTIONS = [
  { key: "0", value: "30", text: "30 days (Default)" },
  { key: "1", value: "7", text: "7 Days" },
  { key: "2", value: "14", text: "14 Days" },
  { key: "3", value: "21", text: "21 Days" },
  { key: "4", value: "-1", text: "Until End of Day" }
]

export default function InfoTab({ siteInfo }) {
  const userLimited = !userNotLimited()

  const [updateInProgress, setUpdateInProgress] = useState(false)
  const [statusMessage, setStatusMessage] = useState(null)

  const [siteName, setSiteName] = useState(siteInfo.name)
  const [address, setAddress] = useState(siteInfo.address)
  const [city, setCity] = useState(siteInfo.city)
  const [state, setState] = useState(siteInfo.state)
  const [zipCode, setZIPCode] = useState(siteInfo.zip)
  const [phoneNumber, setPhoneNumber] = useState(siteInfo.phone)
  const [publicEmail, setPublicEmail] = useState(siteInfo.email)
  const [websiteURL, setWebsiteURL] = useState(siteInfo.website)
  const [daysToShowRecordings, setDaysToShowRecordings] = useState(siteInfo.daystoshowrecordings)
  const [aboveStreamMessage, setAboveStreamMessage] = useState(siteInfo.sitemessage)
  const [belowStreamMessage, setBelowStreamMessage] = useState(siteInfo.sitemessagebottom)
  const [showBelowStreamLinks, setShowBelowStreamLinks] = useState(
    siteInfo.showbelowstreamlinks === 1
  )
  const [webhookKey, setWebhookKey] = useState(siteInfo.iftttwebhookkey)

  useEffect(() => logEvent("Dashboard_Tab_Accessed", { email: getUserEmail(), tab: "info" }), [])

  const getDaysToShowRecordingsOptions = (currentOption) =>
    DAYS_TO_SHOW_RECORDING_OPTIONS.find((x) => x.value === daysToShowRecordings.toString())
      ? DAYS_TO_SHOW_RECORDING_OPTIONS
      : [
          ...DAYS_TO_SHOW_RECORDING_OPTIONS,
          { key: "5", value: currentOption.toString(), text: `${currentOption} Days` }
        ]

  function updateInfo() {
    console.log("Entered updateInfo()")

    setUpdateInProgress(true)
    setStatusMessage(null)
    const url = `${BASE_URL}admin/api/update-info`
    const payload = {
      user: getUserEmail(),
      siteID: siteInfo.siteid,
      siteName,
      address,
      city,
      state,
      zipCode,
      phoneNumber,
      publicEmail,
      websiteURL,
      daysToShowRecordings,
      aboveStreamMessage,
      belowStreamMessage,
      showBelowStreamLinks,
      webhookKey
    }
    axiosWithAuth
      .post(url, payload)
      .then(() => {
        setStatusMessage(
          <span style={{ marginLeft: "10px" }} className="greenFont">
            Updated successfully.
          </span>
        )
        setUpdateInProgress(false)
      })
      .catch(() => {
        setStatusMessage(
          <span style={{ marginLeft: "10px" }} className="redFont">
            An error occurred, please try again.
          </span>
        )
        setUpdateInProgress(false)
      })
  }

  const buildCommonFieldInfoBubble = () => (
    <span>
      &nbsp;
      <InfoBubble
        message={"Will be displayed at the top of your parish's public MassLivestream page."}
      />
    </span>
  )

  const buildDetailsArea = () => (
    <Accordion
      style={{ marginBottom: "15px" }}
      panels={[
        {
          key: "details",
          title: "Additional Information",
          content: {
            content: (
              <div className="additionalInfoBox">
                <p>
                  <i>
                    To make changes to fields under "Additional Information", please&nbsp;
                    <a href={"mailto:support@masslivestream.com"}>contact us</a>.
                  </i>
                </p>
                <p>
                  <b>Plan</b>: {siteInfo.plan}
                </p>
                <p>
                  <b>Site is Public</b>: {boolToYesNo(siteInfo.ispublic)}
                </p>
                <p>
                  <b>Slug</b>: {siteInfo.slug}
                </p>
                <p>
                  <b>Timezone</b>: {siteInfo.timezone}
                </p>
                <p>
                  <b>Photo</b>:<br />
                  <img class="rounded" src={siteInfo.photourl} style={{ width: "100px" }} alt="" />
                </p>
              </div>
            )
          }
        }
      ]}
    />
  )

  const buildBillingArea = () =>
    siteInfo.billingemail && (
      <Accordion
        style={{ marginBottom: "15px" }}
        panels={[
          {
            key: "details",
            title: "Billing Information",
            content: {
              content: (
                <div className="additionalInfoBox">
                  <p>Your parish is set up with automatic monthly billing through Stripe.</p>
                  <p>
                    To view past invoices or change billing payment method, visit the Stripe payment
                    portal using the link below. To access the payment portal, you will be asked to
                    enter your parish's billing email to receive a verification code. The billing
                    email for your parish is&nbsp;
                    <b>
                      <i>{siteInfo.billingemail}</i>
                    </b>
                    .
                  </p>
                  <p>
                    <a
                      href="https://billing.stripe.com/p/login/dR601y1vEgjj89y288"
                      target="_blank"
                      rel="noreferrer">
                      Visit Payment Portal <Icon name="arrow right" />
                    </a>
                  </p>
                </div>
              )
            }
          }
        ]}
      />
    )

  const buildWebhookArea = () => (
    <Accordion
      style={{ marginBottom: "15px" }}
      panels={[
        {
          key: "details",
          title: "Webhook Integration",
          content: {
            content: (
              <div className="additionalInfoBox">
                <p>
                  MassLivestream supports external integrations through&nbsp;
                  <a href="https://ifttt.com/maker_webhooks" target="_blank" rel="noreferrer">
                    IFTTT webhooks
                  </a>
                  . For this integration to work, the IFTTT webhook key must be entered in the field
                  below and the "event name" in IFTTT webhook trigger must be set to
                  "masslivestream-stream".
                </p>
                <p>
                  Webhooks are called for roughly one minute before the stream is scheduled to start
                  (webhooks are not called for private streams or for events with "Do not show in
                  public schedule" selected).
                </p>
                <p>
                  Webhooks are called with "value1" set as a link to the stream and "value2" set
                  with a message including the name of the event.
                </p>
                <p>
                  All connections to external accounts are handled through IFTTT, and management of
                  these connections is done from the IFTTT website. Removing a key value from the
                  field below will cause MassLivestream to stop calling the IFTTT webhook. Please
                  note that MassLivestream has not tested all possible webhook integrations, and
                  using IFTTT integrations is at the user's own risk.
                </p>
                <p>
                  For instructions on how to set up automatic posts to a Facebook page for upcoming
                  events, see&nbsp;
                  <a
                    href="https://drive.google.com/file/d/1lyjLRrOfQElyCO0yd5j-XmEM5CpnhHx0/view?usp=drive_link"
                    target="_blank"
                    rel="noreferrer">
                    here
                  </a>
                  .
                </p>
                <Form.Field>
                  <Label>Webhook Key</Label>
                  <Input
                    value={webhookKey}
                    onChange={(_, data) => setWebhookKey(data.value)}
                    readOnly={userLimited}
                  />
                </Form.Field>
              </div>
            )
          }
        }
      ]}
    />
  )

  const buildFormArea = () => (
    <div>
      <Form className="center-div">
        <Form.Field>
          <Label>Site Name{buildCommonFieldInfoBubble()}</Label>
          <Input
            value={siteName}
            onChange={(_, data) => setSiteName(data.value)}
            readOnly={userLimited}
          />
        </Form.Field>
        <Form.Field>
          <Label>Address{buildCommonFieldInfoBubble()}</Label>
          <Input
            value={address}
            onChange={(_, data) => setAddress(data.value)}
            readOnly={userLimited}
          />
        </Form.Field>
        <Form.Field>
          <Label>City{buildCommonFieldInfoBubble()}</Label>
          <Input value={city} onChange={(_, data) => setCity(data.value)} readOnly={userLimited} />
        </Form.Field>
        <Form.Field>
          <Label>State{buildCommonFieldInfoBubble()}</Label>
          <Input
            value={state}
            onChange={(_, data) => setState(data.value)}
            readOnly={userLimited}
          />
        </Form.Field>
        <Form.Field>
          <Label>ZIP Code{buildCommonFieldInfoBubble()}</Label>
          <Input
            value={zipCode}
            onChange={(_, data) => setZIPCode(data.value)}
            readOnly={userLimited}
          />
        </Form.Field>
        <Form.Field>
          <Label>Phone Number{buildCommonFieldInfoBubble()}</Label>
          <Input
            value={phoneNumber}
            onChange={(_, data) => setPhoneNumber(data.value)}
            readOnly={userLimited}
          />
        </Form.Field>
        <Form.Field>
          <Label>Public Email{buildCommonFieldInfoBubble()}</Label>
          <Input
            value={publicEmail}
            onChange={(_, data) => setPublicEmail(data.value)}
            readOnly={userLimited}
          />
        </Form.Field>
        <Form.Field>
          <Label>Website URL{buildCommonFieldInfoBubble()}</Label>
          <Input
            value={websiteURL}
            onChange={(_, data) => setWebsiteURL(data.value)}
            readOnly={userLimited}
          />
        </Form.Field>
        <Form.Field>
          <Label>
            Days to Show Recordings in Feed&nbsp;
            <InfoBubble
              message={
                <div>
                  <p>
                    The number of days to show recordings in the public feed on your parish's public
                    MassLivestream page.
                  </p>
                  <p>
                    This does not effect how long recordings will be available on the admin
                    dashboard.
                  </p>
                </div>
              }
            />
          </Label>
          <Select
            placeholder={
              getDaysToShowRecordingsOptions(daysToShowRecordings).find(
                (x) => x.value === daysToShowRecordings.toString()
              ).text
            }
            value={daysToShowRecordings}
            options={getDaysToShowRecordingsOptions(daysToShowRecordings)}
            onChange={(_, data) => setDaysToShowRecordings(parseInt(data.value))}
            readOnly={userLimited}
          />
        </Form.Field>
        <Form.Field>
          <Label>
            Above Stream Message&nbsp;
            <InfoBubble
              message={
                <div>
                  <p>
                    A message to show on your parish's public MassLivestream page above the video
                    player.
                  </p>
                  <p>HTML input is supported.</p>
                </div>
              }
            />
          </Label>
          <TextArea
            value={aboveStreamMessage}
            onChange={(_, data) => setAboveStreamMessage(data.value)}
            readOnly={userLimited}
          />
        </Form.Field>
        <Form.Field>
          <Label>
            Bottom of Page Message&nbsp;
            <InfoBubble
              message={
                <div>
                  <p>
                    A message to show on your parish's public MassLivestream page below all other
                    content.
                  </p>
                  <p>HTML input is supported.</p>
                </div>
              }
            />
          </Label>
          <TextArea
            value={belowStreamMessage}
            onChange={(_, data) => setBelowStreamMessage(data.value)}
            readOnly={userLimited}
          />
        </Form.Field>
        <Form.Field style={{ marginBottom: "0" }}>
          <Checkbox
            style={{ float: "left" }}
            checked={showBelowStreamLinks}
            label="Show Materials Links Below Stream"
            control="input"
            type="checkbox"
            onChange={(_, data) => setShowBelowStreamLinks(data.checked)}
            readOnly={userLimited}
          />
          <InfoBubble
            style={{ float: "left" }}
            size={"small"}
            message={
              "Display links to the current day's reading, an act of spiritual communion, and an online missal on your parish's public MassLivestream page below the stream."
            }
          />
        </Form.Field>
        <p>&nbsp;</p>
        {buildWebhookArea()}
        {buildBillingArea()}
        {buildDetailsArea()}
        {userNotLimited() && (
          <Button
            type="submit"
            disabled={updateInProgress}
            loading={updateInProgress}
            onClick={updateInfo}>
            Submit
          </Button>
        )}
        {statusMessage}
      </Form>
    </div>
  )

  return (
    <Tab.Pane attached={false}>
      <Header as="h1" textAlign={"center"}>
        Site Info
      </Header>
      <div className="hrLargeBlue" />
      {buildFormArea()}
    </Tab.Pane>
  )
}
