import { getUserData, getUserEmail, signOut } from "./helpers/Helpers"
import React from "react"
import { Popup } from "semantic-ui-react"

const AppHeader = () => (
  <nav
    className="navbar navbar-light navbar-expand-lg fixed-top bg-secondary text-uppercase headerScroll"
    id="mainNav">
    <div className="container">
      <a className="navbar-brand js-scroll-trigger" href="https://masslivestream.com">
        <img
          id="mainLogo"
          className="mainLogo headerLogo"
          src="https://masslivestream.com/images/MLSLogoIcon.png"
          alt=""
        />
      </a>
      {getUserData() && (
        <div style={{ color: "white" }}>
          <Popup
            content={<span>Logged in as {getUserEmail()}</span>}
            trigger={
              <span className="clickable" onClick={signOut}>
                Sign Out
              </span>
            }
          />
        </div>
      )}
    </div>
  </nav>
)

export default AppHeader
