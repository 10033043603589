import AppHeader from "./AppHeader"
import { logEvent } from "./helpers/Helpers"
import Dashboard from "./pages/Dashboard"
import Login from "./pages/Login"
import ResetPassword from "./pages/ResetPassword"
import React from "react"
import { Route, Switch } from "react-router-dom"

function overrideLogging() {
  window.console = ((oldCons) => ({
    log: (text) => oldCons.log(text),
    info: (text) => oldCons.info(text),
    warn: (text) => oldCons.warn(text),
    error: (text) => {
      oldCons.error(text)
      logEvent("Dashboard_Error", { error: text.replace(/\?/g, "") })
    }
  }))(window.console)
}

const App = () => (
  <div className={"main"}>
    <AppHeader />
    <Switch>
      <Route path="/reset-password" component={() => <ResetPassword />} />
      <Route path="/login" component={() => <Login />} />
      <Route path="/" component={() => <Dashboard />} />
      {overrideLogging()}
    </Switch>
  </div>
)

export default App
