import { COPY_TO_CLIPBOARD_TYPES } from "../helpers/Constants"
import { useState } from "react"
import { Button, Icon, Popup } from "semantic-ui-react"

export function CopyToClipboardButton({ type, textToCopy, typeOverride, hide }) {
  const [showPopup, setShowPopup] = useState(false)

  const trigger =
    type === COPY_TO_CLIPBOARD_TYPES.BUTTON ? (
      <Button onClick={copyToClipboard}>Copy {typeOverride ? typeOverride : "Link"}</Button>
    ) : (
      <Icon
        style={{ marginLeft: "5px" }}
        className="clickable"
        name="copy outline"
        onClick={copyToClipboard}
      />
    )

  function copyToClipboard() {
    navigator.clipboard.writeText(textToCopy)
    document.execCommand("Copy")

    setShowPopup(true)
    setTimeout(() => {
      setShowPopup(false)
    }, 500)
  }

  return !hide && <Popup inverted open={showPopup} content={"Copied!"} trigger={trigger} />
}
