import { axiosWithAuth } from "../helpers/AxiosInterceptor"
import { BASE_URL } from "../helpers/Constants"
import { InfoBubble, getUserEmail, logEvent } from "../helpers/Helpers"
import { LoadingError } from "./LoadingError"
import { useEffect, useState } from "react"
import {
  Button,
  Dropdown,
  Header,
  Loader,
  Modal,
  Input,
  Label,
  Form,
  Message,
  Checkbox
} from "semantic-ui-react"

const TIME_STRING_REGEX = new RegExp("[0-9]{2}:[0-9]{2}:[0-9]{2}$")

export function CreateClipModal({ siteInfo, setStatusCallback, reloadClipsCallback }) {
  const [showModal, setShowModal] = useState(false)
  const [createInProgress, setCreateInProgress] = useState(false)
  const [statusMessage, setStatusMessage] = useState(null)
  const [recordings, setRecordings] = useState(null)
  const [recordingsLoaded, setRecordingsLoaded] = useState(false)
  const [recordingsLoadError, setRecordingsLoadError] = useState(false)
  const [recordingToClip, setRecordingToClip] = useState(null)
  const [title, setTitle] = useState(null)
  const [titleError, setTitleError] = useState(null)
  const [startTime, setStartTime] = useState("00:00:00")
  const [startTimeError, setStartTimeError] = useState(null)
  const [endTime, setEndTime] = useState("00:00:00")
  const [endTimeError, setEndTimeError] = useState(null)
  const [makePrivate, setMakePrivate] = useState(false)
  const [audioOnly, setAudioOnly] = useState(false)

  useEffect(
    () =>
      showModal && logEvent("Dashboard_Modal_Accessed", { email: getUserEmail(), modal: "clip" }),
    [showModal]
  )

  // eslint-disable-next-line
  useEffect(() => loadRecordings(), [])

  useEffect(() => {
    const video = document.getElementById("video")
    if (video) {
      video.src = recordingToClip.url
    }
  }, [recordingToClip])

  function loadRecordings() {
    console.log("Entering loadRecordings()")

    setRecordingsLoaded(false)
    setRecordingsLoadError(false)
    const url = `${BASE_URL}admin/api/site/${siteInfo.siteid}/recordings`
    axiosWithAuth
      .get(url)
      .then((response) => {
        setRecordings(response.data)
        setRecordingsLoaded(true)
      })
      .catch(() => {
        setRecordingsLoaded(true)
        setRecordingsLoadError(true)
      })
  }

  function createClip() {
    console.log(`Entered createClip()`)

    if (validateInputs()) {
      setStatusMessage(null)
      setCreateInProgress(true)

      const url = `${BASE_URL}admin/api/create-clip`
      axiosWithAuth
        .post(url, {
          user: getUserEmail(),
          siteID: siteInfo.siteid,
          title,
          startTime,
          endTime,
          makePrivate,
          audioOnly,
          videoURL: recordingToClip.url,
          fromSchedID: recordingToClip.scheduleid,
          fromSchedTitle: recordingToClip.schedtitle,
          fromSchedTime: recordingToClip.time
        })
        .then(() => {
          setStatusCallback({
            success: true,
            infoString:
              "Your clip has been submitted for clipping. This process may take a few minutes to complete."
          })
          resetAndClose()
        })
        .catch(() => {
          setStatusMessage(<span className="redFont">An error occurred, please try again.</span>)
          setCreateInProgress(false)
        })
    }
  }

  function validateInputs() {
    console.log(`Entered validateInputs()`)

    const getSeconds = (timeStr) => {
      let retVal = -1
      const parts = timeStr.split(":")

      if (
        TIME_STRING_REGEX.test(timeStr) &&
        parts.length === 3 &&
        !isNaN(parts[0]) &&
        !isNaN(parts[1]) &&
        !isNaN(parts[2]) &&
        parseInt(parts[0]) < 60 &&
        parseInt(parts[1]) < 60 &&
        parseInt(parts[2]) < 60
      ) {
        retVal = parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2])
      }

      return retVal
    }

    const titleError =
      title && title.length > 3 ? null : (
        <span className="error">Clip title must be at least four characters</span>
      )
    setTitleError(titleError)

    const startSeconds = getSeconds(startTime)
    const endSeconds = getSeconds(endTime)
    const startTimeError =
      startSeconds === -1 ? <span className="error">Invalid format (must be hh:mm:ss)</span> : null
    setStartTimeError(startTimeError)
    const endTimeError =
      endSeconds === -1 ? (
        <span className="error">Invalid format (must be hh:mm:ss)</span>
      ) : startSeconds > endSeconds - 60 ? (
        <span className="error">End time must be at least 60 seconds greater than start time</span>
      ) : null
    setEndTimeError(endTimeError)

    return !titleError && !startTimeError && !endTimeError
  }

  function resetAndClose() {
    setRecordingToClip(null)
    setTitle(null)
    setTitleError(null)
    setStartTime("00:00:00")
    setStartTimeError(null)
    setEndTime("00:00:00")
    setEndTimeError(null)
    setCreateInProgress(false)
    setStatusMessage(null)

    setShowModal(false)
    reloadClipsCallback()
  }

  const getRecordingToClip = (id) => recordings.find((recording) => recording.scheduleid === id)

  const getRecordingsAsOptions = () =>
    recordings.map((recording) => ({
      value: recording.scheduleid,
      text: `${recording.schedtitle} (${recording.time})`
    }))

  const buildClippingArea = () => (
    <div style={{ marginTop: "25px" }}>
      <video id="video" width="100%" controls controlsList="nodownload">
        <source type="video/mp4" />
      </video>
      <div style={{ fontStyle: "italic", textAlign: "center" }}>
        Hover the mouse over or pause the video to display the current timestamp.
      </div>
      <Form style={{ marginTop: "25px" }} className="center-div">
        <Form.Field>
          <Label>Clip TItle</Label> {titleError}
          <Input value={title} onChange={(_, data) => setTitle(data.value)} />
        </Form.Field>
        <Form.Field>
          <Label>Start Time (format hh:mm:ss)</Label> {startTimeError}
          <Input value={startTime} onChange={(_, data) => setStartTime(data.value)} />
        </Form.Field>
        <Form.Field>
          <Label>End Time (format hh:mm:ss)</Label> {endTimeError}
          <Input value={endTime} onChange={(_, data) => setEndTime(data.value)} />
        </Form.Field>
        <Form.Field>
          <Checkbox
            style={{ float: "left" }}
            label="Do Not Show in Public Feed"
            control="input"
            type="checkbox"
            onChange={(_, data) => setMakePrivate(data.checked)}
          />
          <InfoBubble
            style={{ float: "left" }}
            size={"small"}
            message={
              "Clip will not be listed on your parish's public MassLivestream page, but can be access via the admin dashboard and shared via permalink."
            }
          />
        </Form.Field>
        <p>&nbsp;</p>
        <Form.Field>
          <Checkbox
            style={{ float: "left" }}
            label="Audio Only"
            control="input"
            type="checkbox"
            onChange={(_, data) => setAudioOnly(data.checked)}
          />
          <InfoBubble
            style={{ float: "left" }}
            size={"small"}
            message={"Creates an audio-only MP3 clips from the selected recording period."}
          />
        </Form.Field>
        <Message error header="Form Errors" content="123" />
      </Form>
    </div>
  )

  const buildModalContent = () =>
    recordingsLoadError ? (
      <LoadingError />
    ) : !recordingsLoaded ? (
      <Loader active inline="centered" />
    ) : recordings && recordings.length === 0 ? (
      <Header as="h3" textAlign="center" style={{ marginTop: "20px" }}>
        No recordings found, cannot create a clip.
      </Header>
    ) : (
      recordings && (
        <div className="center-div" style={{ width: "100%" }}>
          <Header as="h4">Select/change recording to clip:</Header>
          <Dropdown
            fluid
            search
            selection
            options={getRecordingsAsOptions()}
            onChange={(_, data) => setRecordingToClip(getRecordingToClip(data.value))}
          />
          {recordingToClip && buildClippingArea()}
        </div>
      )
    )

  return (
    <Modal
      showModal={showModal}
      trigger={<Button className="blue">Create Clip</Button>}
      onClose={resetAndClose}
      onOpen={() => setShowModal(true)}
      open={showModal}
      size={"large"}>
      <Header icon="edit" content={`Create a clip`} />
      <Modal.Content>{buildModalContent()}</Modal.Content>
      <Modal.Actions>
        {statusMessage}
        <Button disabled={createInProgress} onClick={resetAndClose}>
          Close
        </Button>
        <Button
          className="blue"
          disabled={createInProgress || !recordingToClip}
          loading={createInProgress}
          onClick={createClip}>
          Create Clip
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
