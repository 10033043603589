import { CopyToClipboardButton } from "../../components/CopyToClipboardButton"
import { LoadingError } from "../../components/LoadingError"
import { ReloadDataButton } from "../../components/ReloadDataButton"
import { StreamDisplay } from "../../components/StreamDisplay"
import { axiosWithAuth } from "../../helpers/AxiosInterceptor"
import { BASE_URL, COPY_TO_CLIPBOARD_TYPES } from "../../helpers/Constants"
import { InfoBubble, getUserEmail, logEvent } from "../../helpers/Helpers"
import React, { useState, useEffect } from "react"
import { Header, Tab, Loader, Input, Form, Label } from "semantic-ui-react"

export default function StreamsTab({ siteInfo }) {
  const [streams, setStreams] = useState(null)
  const [streamsLoaded, setStreamsLoaded] = useState(false)
  const [streamsLoadError, setStreamsLoadError] = useState(false)

  useEffect(() => logEvent("Dashboard_Tab_Accessed", { email: getUserEmail(), tab: "streams" }), [])
  // eslint-disable-next-line
  useEffect(() => loadStreams(), [siteInfo])

  function loadStreams() {
    console.log("Entering loadStreams()")
    setStreamsLoaded(false)
    const url = `${BASE_URL}admin/api/site/${siteInfo.siteid}/streams`
    axiosWithAuth
      .get(url)
      .then((response) => {
        setStreams(response.data)
        setStreamsLoaded(true)
      })
      .catch(() => {
        setStreamsLoaded(true)
        setStreamsLoadError(true)
      })
  }

  const toggleStreamCallback = (id) =>
    setStreams(
      streams.map((stream) =>
        stream.streamid === id ? { ...stream, streamisalive: !stream.streamisalive } : stream
      )
    )

  const updateStreamTitleCallback = (response) =>
    setStreams(
      streams.map((stream) =>
        stream.streamid === response.id ? { ...stream, streamname: response.newTitle } : stream
      )
    )

  const buildStreams = () => (
    <div>
      {streams && streams.length > 0 ? (
        streams.map((stream) => (
          <StreamDisplay
            key={stream.streamid}
            siteSlug={siteInfo.slug}
            stream={stream}
            toggleStreamCallback={toggleStreamCallback}
            updateStreamTitleCallback={updateStreamTitleCallback}
          />
        ))
      ) : (
        <Header as="h3" textAlign="center" style={{ marginTop: "20px" }}>
          No streams found.
        </Header>
      )}
    </div>
  )

  const buildOverallStatusLinkArea = () => (
    <Form.Field
      className="center-div"
      style={{ marginTop: "15px", marginBottom: "15px", maxWidth: "100%" }}>
      <Label>
        Stream Status Embed Code (All Streams)&nbsp;
        <InfoBubble
          message={
            <div>
              <p>
                Customizable HTML embed code that defaults to show the current stream image for your
                parish's ongoing/next event, the title of the your parish's ongoing/next event, and
                a link to your parish's public MassLivestream page.
              </p>
              <p>
                This code can be embedded on your parish website to link visitors to your parish's
                public MassLivestream page.
              </p>
            </div>
          }
        />
      </Label>
      <Input
        style={{ width: "520px", maxWidth: "100%" }}
        value={siteInfo.streamstatusembedcode}
        readOnly={true}
      />
      <CopyToClipboardButton
        type={COPY_TO_CLIPBOARD_TYPES.ICON}
        textToCopy={siteInfo.streamstatusembedcode}
      />
    </Form.Field>
  )

  return (
    <Tab.Pane attached={false}>
      <ReloadDataButton callback={loadStreams} />
      <Header as="h1" textAlign={"center"}>
        Streams
      </Header>
      <div className="hrLargeBlue" />
      {streamsLoadError ? (
        <LoadingError />
      ) : !streamsLoaded ? (
        <Loader active inline="centered" />
      ) : (
        <div>
          {buildOverallStatusLinkArea()}
          {buildStreams()}
        </div>
      )}
    </Tab.Pane>
  )
}
