import { BASE_URL } from "../helpers/Constants"
import { getUserData, setUserData } from "../helpers/Helpers"
import { PASSWORD_RESET_HASH } from "./ResetPassword"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { Button, Form, Grid, Segment, Loader, Accordion } from "semantic-ui-react"

export default function Login() {
  const userData = getUserData()

  const [hasJWT, setHasJWT] = useState(userData && !!userData.jwt)
  const [loginCheckDone, setLoginCheckDone] = useState(false)
  const [loginFailed, setLoginFailed] = useState(false)
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [passwordResetInProgress, setPasswordResetInProgress] = useState(false)
  const [passwordResetMessage, setPasswordResetMessage] = useState()

  // eslint-disable-next-line
  useEffect(() => loginCheck(), [])

  const emailValid = () => email && email.includes("@") && email.includes(".")

  const urlHasPasswordResetHash = () => window.location.hash === PASSWORD_RESET_HASH

  function login() {
    console.log(`Entered login()`)

    setHasJWT(false)
    setLoginFailed(false)
    if (emailValid() && password) {
      const url = `${BASE_URL}admin/api/login`
      axios
        .post(url, {
          email,
          password
        })
        .then((response) => {
          setUserData(response.data)
          window.location.href = "/"
        })
        .catch(() => setLoginFailed(true))
    }
  }

  function loginCheck() {
    console.log(`Entered loginCheck()`)

    let globalAdminData
    try {
      const globalAdminDataBytes = document.cookie
        .split(";")
        .filter((x) => x.includes("globalAdminData="))[0]
        .split("=")[1]
      const globalAdminDataJSON = atob(globalAdminDataBytes)
      globalAdminData = JSON.parse(globalAdminDataJSON)
    } catch (ignored) {}
    if (globalAdminData) {
      console.log("Using global admin cookie data")
      setUserData(globalAdminData)
      setHasJWT(globalAdminData && !!globalAdminData.jwt)
    }

    const url = `${BASE_URL}admin/api/login-check`
    if (hasJWT) {
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${userData.jwt || globalAdminData.jwt}` }
        })
        .then(() => {
          window.location.href = "/"
        })
        .catch(() => {
          setLoginCheckDone(true)
          setUserData(null)
        })
    } else {
      setLoginCheckDone(true)
    }
  }

  function triggerPasswordReset() {
    console.log(`Entered triggerPasswordReset()`)

    if (emailValid()) {
      setPasswordResetInProgress(true)
      setPasswordResetMessage(null)

      const url = `${BASE_URL}admin/api/trigger-password-reset`
      axios
        .post(url, {
          email
        })
        .then(() => {
          setPasswordResetMessage(
            <div className="success">
              Password reset request received. Check your email address.
            </div>
          )
          setPasswordResetInProgress(false)
        })
        .catch(() => {
          setPasswordResetMessage(
            <div className="error">Password reset failed, please try again.</div>
          )
          setPasswordResetInProgress(false)
        })
    }
  }

  const buildLoginForm = () => (
    <Grid textAlign="center" style={{ height: "100vh" }}>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Segment stacked>
          <Form size="large">
            {loginFailed && <div className="error">Login failed, please try again.</div>}
            {hasJWT && loginCheckDone && (
              <div className="error">Session has expired, please login again.</div>
            )}
            {!loginFailed && urlHasPasswordResetHash() && (
              <div className="success">Password reset successfully, please login below.</div>
            )}
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Email"
              type="email"
              onChange={(_, data) => setEmail(data.value)}
              required
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              onChange={(_, data) => setPassword(data.value)}
              required
            />
            <Button color="blue" fluid size="large" onClick={login}>
              Login
            </Button>
          </Form>
          <Accordion
            style={{ marginTop: "15px" }}
            panels={[
              {
                key: "resetPassword",
                title: "Reset Password",
                content: {
                  content: (
                    <Segment style={{ marginTop: "10px" }}>
                      {passwordResetMessage}
                      <Form size="small">
                        <Form.Input
                          icon="user"
                          iconPosition="left"
                          placeholder="Email"
                          type="email"
                          onChange={(_, data) => setEmail(data.value)}
                          required
                        />
                        <Button
                          color="blue"
                          loading={passwordResetInProgress}
                          onClick={triggerPasswordReset}>
                          Reset Password
                        </Button>
                      </Form>
                    </Segment>
                  )
                }
              }
            ]}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  )

  return !loginCheckDone ? (
    <Loader active inline="centered" />
  ) : (
    <div style={{ marginTop: "50px" }}>
      <h1 style={{ textAlign: "center", marginBottom: "25px" }}>Login to MassLivestream</h1>
      {buildLoginForm()}
    </div>
  )
}
