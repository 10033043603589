import { CreateClipModal } from "../../components/CreateClipModal"
import { CustomTable } from "../../components/CustomTable"
import { LoadingError } from "../../components/LoadingError"
import { ReloadDataButton } from "../../components/ReloadDataButton"
import { buildClipsTableConfig } from "../../configs/ClipsTableConfig"
import { axiosWithAuth } from "../../helpers/AxiosInterceptor"
import { BASE_URL } from "../../helpers/Constants"
import { getUserEmail, logEvent, triggerTabError } from "../../helpers/Helpers"
import React, { useState, useEffect } from "react"
import { Tab, Loader, Header } from "semantic-ui-react"

export default function ClipsTab({ siteInfo }) {
  const [clips, setClips] = useState(null)
  const [clipsLoaded, setClipsLoaded] = useState(false)
  const [clipsLoadError, setClipsLoadError] = useState(false)
  const [errorState, setErrorState] = useState(false)
  const [status, setStatus] = useState(false)

  // eslint-disable-next-line
  useEffect(() => logEvent("Dashboard_Tab_Accessed", { email: getUserEmail(), tab: "clips" }), [])
  // eslint-disable-next-line
  useEffect(() => loadClips(), [])

  function loadClips() {
    console.log("Entering loadClips()")
    setClipsLoaded(false)
    const url = `${BASE_URL}admin/api/site/${siteInfo.siteid}/clips`
    axiosWithAuth
      .get(url)
      .then((response) => {
        setClips(response.data)
        setClipsLoaded(true)
      })
      .catch(() => {
        setClipsLoaded(true)
        setClipsLoadError(true)
      })
  }

  function updateTitleCallback(response) {
    if (response.success) {
      setClips(
        clips.map((clip) =>
          clip.clipid === response.id ? { ...clip, cliptitle: response.newTitle } : clip
        )
      )
    }
  }

  function togglePublicCallback(response) {
    if (response.success) {
      setClips(
        clips.map((clip) =>
          clip.clipid === response.id ? { ...clip, ispublic: !clip.ispublic } : clip
        )
      )
    } else {
      triggerTabError(setErrorState)
    }
  }

  function deleteItemCallback(response) {
    if (response.success) {
      setClips(clips.filter((clip) => clip.clipid !== response.id))
    }
  }

  function setStatusCallback(messageData) {
    setStatus(messageData)
    setTimeout(function () {
      setStatus(null)
    }, 3000)
  }

  return (
    <Tab.Pane attached={false}>
      <ReloadDataButton callback={loadClips} />
      <Header as="h1" textAlign={"center"}>
        Clips
      </Header>
      <div className="hrLargeBlue" />
      {errorState && <div className="error">An error occurred, please try again.</div>}
      {status && <div className={status.success ? "success" : "error"}>{status.infoString}</div>}
      <div style={{ textAlign: "center", marginBottom: "15px" }}>
        <CreateClipModal
          siteInfo={siteInfo}
          setStatusCallback={setStatusCallback}
          reloadClipsCallback={loadClips}
        />
      </div>
      {clipsLoadError ? (
        <LoadingError />
      ) : !clipsLoaded ? (
        <Loader active inline="centered" />
      ) : clips && clips.length === 0 ? (
        <Header as="h3" textAlign="center" style={{ marginTop: "20px" }}>
          No clips found.
        </Header>
      ) : (
        clips && (
          <CustomTable
            tableColumns={buildClipsTableConfig(
              siteInfo.siteid,
              siteInfo.slug,
              updateTitleCallback,
              togglePublicCallback,
              deleteItemCallback
            )}
            tableData={clips}
          />
        )
      )}
    </Tab.Pane>
  )
}
