import { axiosWithAuth } from "../helpers/AxiosInterceptor"
import { BASE_URL } from "../helpers/Constants"
import { getUserEmail, userNotLimited } from "../helpers/Helpers"
import { useState } from "react"
import { Button, Header, Icon, Input, Modal } from "semantic-ui-react"

export function UpdateItemTitleModal({ type, id, currentTitle, callback }) {
  const [showModal, setShowModal] = useState(false)
  const [title, setTitle] = useState(currentTitle)
  const [statusMessage, setStatusMessage] = useState(null)
  const [updateInProgress, setUpdateInProgress] = useState(false)

  function updateItemTitle(type, id, newTitle) {
    console.log(`Entered updateItemTitle(${type}, ${id}, ${newTitle}`)

    const url = `${BASE_URL}admin/api/update-item-title`
    axiosWithAuth
      .post(url, {
        validateSiteForType: true,
        user: getUserEmail(),
        type,
        id,
        newTitle
      })
      .then(() => {
        setStatusMessage(
          <div style={{ marginBottom: "10px", textAlign: "center", color: "green" }}>
            Title updated successfully.
          </div>
        )
        setUpdateInProgress(false)
        callback({
          success: true,
          type,
          id,
          newTitle
        })
      })
      .catch(() => {
        setStatusMessage(<div className="error">An error occurred, please try again.</div>)
        setUpdateInProgress(false)
        callback({
          success: false,
          type,
          id,
          newTitle
        })
      })
  }

  function resetAndClose() {
    setTitle(currentTitle)
    setStatusMessage(null)
    setShowModal(false)
  }

  return (
    userNotLimited() && (
      <Modal
        showModal={showModal}
        trigger={<Icon className="clickable" name="edit" />}
        onClose={resetAndClose}
        onOpen={() => setShowModal(true)}
        open={showModal}
        style={{ width: "500px", maxWidth: "100%" }}>
        <Header icon="edit" content={`Update ${type} title`} />
        <Modal.Content>
          {statusMessage}
          <Input
            style={{ width: "100%" }}
            value={title}
            onChange={(_, data) => setTitle(data.value)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={updateInProgress} onClick={resetAndClose}>
            Close
          </Button>
          <Button
            className="blue"
            disabled={updateInProgress}
            loading={updateInProgress}
            onClick={() => {
              setUpdateInProgress(true)
              setStatusMessage(null)
              updateItemTitle(type, id, title)
            }}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  )
}
