import { LoadingError } from "../components/LoadingError"
import { axiosWithAuth } from "../helpers/AxiosInterceptor"
import { BASE_URL } from "../helpers/Constants"
import { getUserData } from "../helpers/Helpers"
import ClipsTab from "./dashboard_tabs/ClipsTab"
import InfoTab from "./dashboard_tabs/InfoTab"
import RecordingsTab from "./dashboard_tabs/RecordingsTab"
import ScheduleTab from "./dashboard_tabs/ScheduleTab"
import StatsTab from "./dashboard_tabs/StatsTab"
import StreamsTab from "./dashboard_tabs/StreamsTab"
import React, { useState, useEffect } from "react"
import { Container, Tab, Loader, Header, Button } from "semantic-ui-react"

export default function Dashboard({ defaultActiveIndex }) {
  const [siteInfo, setSiteInfo] = useState(null)
  const [siteInfoLoaded, setSiteInfoLoaded] = useState(false)
  const [siteInfoLoadError, setSiteInfoLoadError] = useState(false)

  const tabPanes = [
    {
      menuItem: "Site Info",
      render: () => <InfoTab siteInfo={siteInfo} />
    },
    {
      menuItem: "Streams",
      render: () => <StreamsTab siteInfo={siteInfo} />
    },
    {
      menuItem: "Schedule",
      render: () => <ScheduleTab siteInfo={siteInfo} />
    },
    {
      menuItem: "Recordings",
      render: () => <RecordingsTab siteInfo={siteInfo} />
    },
    {
      menuItem: "Clips",
      render: () => <ClipsTab siteInfo={siteInfo} />
    },
    {
      menuItem: "Stats",
      render: () => <StatsTab siteInfo={siteInfo} />
    }
  ]

  useEffect(() => loadSiteInfo(), [])

  function loadSiteInfo() {
    console.log("Entering loadSiteInfo()")

    setSiteInfoLoaded(false)
    const url = `${BASE_URL}admin/api/site/${getUserData() && getUserData().adminForSite}`
    axiosWithAuth
      .get(url)
      .then((response) => {
        setSiteInfo(response.data)
        setSiteInfoLoaded(true)
      })
      .catch(() => {
        setSiteInfoLoaded(true)
        setSiteInfoLoadError(true)
      })
  }

  const buildDashboard = () => (
    <Container style={{ marginTop: "50px", width: "1300px" }}>
      {siteInfoLoadError ? (
        <LoadingError />
      ) : !siteInfoLoaded ? (
        <Loader active inline="centered" />
      ) : (
        siteInfo && (
          <div>
            <Container textAlign={"center"}>
              <Header as="h1" style={{ fontSize: "33px" }}>
                MassLivestream Admin Dashboard for <i>{siteInfo.name}</i>
              </Header>
              <a href={`${BASE_URL}churches/${siteInfo.slug}`} target="_blank" rel="noreferrer">
                <Button>View Public Page</Button>
              </a>
            </Container>
            <Tab
              style={{ marginTop: "20px" }}
              menu={{ secondary: true, pointing: false }}
              panes={tabPanes}
              defaultActiveIndex={defaultActiveIndex}
            />
          </div>
        )
      )}
    </Container>
  )

  return buildDashboard()
}
