import { axiosWithAuth } from "../helpers/AxiosInterceptor"
import { BASE_URL } from "../helpers/Constants"
import { boolToYesNo, getUserEmail, userNotLimited } from "../helpers/Helpers"
import { Icon } from "semantic-ui-react"

export function ToggleItemSwitch({ siteID, type, fieldType, id, itemValue, callback }) {
  function toggleItem(type, id, value, callback) {
    console.log(`Entered toggleItem(${type}, ${id})`)
    const url = `${BASE_URL}admin/api/toggle-item`
    axiosWithAuth
      .post(url, {
        validateSiteForType: true,
        user: getUserEmail(),
        type,
        id,
        value,
        field: fieldType
      })
      .then(() => {
        callback({
          success: true,
          type,
          id
        })
      })
      .catch(() => {
        callback({
          success: false,
          type,
          id
        })
      })
  }

  return (
    <div>
      {boolToYesNo(itemValue)}&nbsp;
      {userNotLimited() && (
        <Icon
          className="clickable"
          name={itemValue ? "toggle on" : "toggle off"}
          onClick={() => toggleItem(type, id, itemValue, callback)}
        />
      )}
    </div>
  )
}
