import { CustomTable } from "../../components/CustomTable"
import { LoadingError } from "../../components/LoadingError"
import { ReloadDataButton } from "../../components/ReloadDataButton"
import { ScheduleEventModal, SET_TYPE, SINGLE_TYPE } from "../../components/ScheduleEventModal"
import { buildScheduleTableConfig } from "../../configs/ScheduleTableConfig"
import { axiosWithAuth } from "../../helpers/AxiosInterceptor"
import { BASE_URL } from "../../helpers/Constants"
import { getUserEmail, logEvent } from "../../helpers/Helpers"
import React, { useState, useEffect } from "react"
import { Tab, Loader, Header } from "semantic-ui-react"

export default function ScheduleTab({ siteInfo }) {
  const [schedule, setSchedule] = useState(null)
  const [scheduleLoaded, setScheduleLoaded] = useState(false)
  const [scheduleLoadError, setScheduleLoadError] = useState(false)
  const [status, setStatus] = useState(false)

  useEffect(
    () => logEvent("Dashboard_Tab_Accessed", { email: getUserEmail(), tab: "schedule" }),
    []
  )
  // eslint-disable-next-line
  useEffect(() => loadSchedule(), [])

  function loadSchedule() {
    console.log("Entering loadSchedule()")

    setScheduleLoaded(false)
    const url = `${BASE_URL}admin/api/site/${siteInfo.siteid}/schedule`
    axiosWithAuth
      .get(url)
      .then((response) => {
        setSchedule(response.data)
        setScheduleLoaded(true)
      })
      .catch(() => {
        setScheduleLoaded(true)
        setScheduleLoadError(true)
      })
  }

  function setStatusCallback(messageData) {
    setStatus(messageData)
    setTimeout(function () {
      setStatus(null)
    }, 3000)
  }

  function deleteItemCallback(response) {
    if (response.success) {
      const field =
        response.type === SINGLE_TYPE
          ? "scheduleid"
          : response.type === SET_TYPE
          ? "setid"
          : "seriesid"
      setSchedule(schedule.filter((sched) => sched[field] !== response.id))
    }
  }

  return (
    <Tab.Pane attached={false}>
      <ReloadDataButton callback={loadSchedule} />
      <Header as="h1" textAlign={"center"}>
        Schedule
      </Header>
      <div className="hrLargeBlue" />
      {status && <div className={status.success ? "success" : "error"}>{status.infoString}</div>}
      <div style={{ textAlign: "center", marginBottom: "15px" }}>
        <ScheduleEventModal
          siteInfo={siteInfo}
          setStatusCallback={setStatusCallback}
          reloadScheduleCallback={loadSchedule}
        />
      </div>
      {scheduleLoadError ? (
        <LoadingError />
      ) : !scheduleLoaded ? (
        <Loader active inline="centered" />
      ) : schedule && schedule.length === 0 ? (
        <Header as="h3" textAlign="center" style={{ marginTop: "20px" }}>
          No events found.
        </Header>
      ) : (
        schedule && (
          <CustomTable
            tableColumns={buildScheduleTableConfig(
              siteInfo,
              setStatusCallback,
              deleteItemCallback,
              loadSchedule
            )}
            tableData={schedule}
          />
        )
      )}
    </Tab.Pane>
  )
}
