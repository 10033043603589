import { ADMIN_API_KEY, BASE_URL, ITEM_TYPES } from "./Constants"
import axios from "axios"
import { Popup, Icon } from "semantic-ui-react"

export const boolToYesNo = (bool) => (!!bool ? "Yes" : "No")

export function logEvent(event, info) {
  const url = `${BASE_URL}api/logevent`
  axios.post(url, {
    payload: JSON.stringify({ key: ADMIN_API_KEY, event, info })
  })
}

export function signOut() {
  localStorage.setItem("user", null)
  window.location.href = "/login"
}

export function triggerTabError(errorSetter) {
  errorSetter(true)
  setTimeout(function () {
    errorSetter(false)
  }, 3000)
}

export const buildItemURL = (type, slug, id, isPrivate, key) =>
  isPrivate
    ? `${BASE_URL}private/${key}`
    : `${BASE_URL}churches/${slug}?${type === ITEM_TYPES.RECORDING ? "rec" : type}ID=${id}`

export const buildItemPermalinkURL = (key) => `${BASE_URL}permalink/${key}`

export const buildEmbedCode = (type, id) =>
  `<iframe src="${BASE_URL}embed?${
    type === ITEM_TYPES.RECORDING ? "rec" : "clip"
  }ID=${id}" name="player" width="800" height="458" scrolling="no" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen="true"></iframe>`

export const getUserData = () => JSON.parse(localStorage.getItem("user"))

export const getUserEmail = () => {
  const userData = getUserData()
  return userData && userData.email
}

export const setUserData = (data) => localStorage.setItem("user", JSON.stringify(data))

export const userNotLimited = () => getUserData().limitedAccount === 0

export const InfoBubble = ({ style, message, size, disabled }) => (
  <Popup
    content={message}
    trigger={<Icon style={style} name="info circle" disabled={disabled} size={size} />}
  />
)
