import { CopyToClipboardButton } from "../components/CopyToClipboardButton"
import { DeleteItemModal } from "../components/DeleteItemModal"
import { DownloadItemModal } from "../components/DownloadItemModal"
import { ToggleItemSwitch } from "../components/ToggleItemSwitch"
import { UpdateItemTitleModal } from "../components/UpdateItemTitleModal"
import { ViewItemButton } from "../components/ViewItemButton"
import { COPY_TO_CLIPBOARD_TYPES, ITEM_TYPES, TOGGLE_FIELD_TYPES } from "../helpers/Constants"
import {
  buildEmbedCode,
  buildItemPermalinkURL,
  InfoBubble,
  userNotLimited
} from "../helpers/Helpers"

export const buildRecordingsTableColumns = (
  siteID,
  siteSlug,
  updateTitleCallback,
  togglePublicCallback,
  toggleSavedCallback,
  deleteItemCallback
) => [
  {
    title: "Title",
    field: "schedtitle",
    render: (rowData) => (
      <div>
        <b>{rowData.schedtitle}</b>&nbsp;
        <UpdateItemTitleModal
          type={ITEM_TYPES.RECORDING}
          id={rowData.scheduleid}
          currentTitle={rowData.schedtitle}
          callback={updateTitleCallback}
        />
      </div>
    )
  },
  { title: "Stream", field: "streamname" },
  { title: "Date", field: "time" },
  {
    title: (
      <span>
        In Public Feed&nbsp;
        <InfoBubble
          size={"small"}
          message={
            <div>
              <p>
                Whether or not the recording is listed on your parish's public MassLivestream page.
              </p>
              <p>Clips can always be access via the admin dashboard and shared via permalink.</p>
            </div>
          }
        />
      </span>
    ),
    field: "recordingispublic",
    render: (rowData) => (
      <ToggleItemSwitch
        siteID={siteID}
        type={ITEM_TYPES.RECORDING}
        fieldType={TOGGLE_FIELD_TYPES.PUBLIC}
        id={rowData.scheduleid}
        itemValue={rowData.recordingispublic}
        callback={togglePublicCallback}
      />
    )
  },
  {
    title: (
      <span>
        Saved&nbsp;
        <InfoBubble
          size={"small"}
          message={
            <div>
              <p>Whether or not the recording is marked as saved.</p>
              <p>Saved recordings are not deleted after 30 days.</p>
            </div>
          }
        />
      </span>
    ),
    field: "keeprecording",
    render: (rowData) => (
      <ToggleItemSwitch
        siteID={siteID}
        type={ITEM_TYPES.RECORDING}
        fieldType={TOGGLE_FIELD_TYPES.SAVED}
        id={rowData.scheduleid}
        itemValue={rowData.keeprecording}
        callback={toggleSavedCallback}
      />
    )
  },
  {
    title: "",
    render: (rowData) => (
      <ViewItemButton
        type={ITEM_TYPES.RECORDING}
        slug={siteSlug}
        id={rowData.scheduleid}
        isPrivate={!rowData.recordingispublic}
        itemKey={rowData.key}
      />
    )
  },
  {
    title: "",
    render: (rowData) => (
      <CopyToClipboardButton
        type={COPY_TO_CLIPBOARD_TYPES.BUTTON}
        textToCopy={buildItemPermalinkURL(rowData.key)}
        typeOverride={"Permalink"}
      />
    )
  },
  {
    title: "",
    render: (rowData) =>
      rowData.recordingispublic ? (
        <CopyToClipboardButton
          type={COPY_TO_CLIPBOARD_TYPES.BUTTON}
          textToCopy={buildEmbedCode(ITEM_TYPES.RECORDING, rowData.scheduleid)}
          typeOverride={"Embed Code"}
        />
      ) : null
  },
  {
    title: "",
    render: (rowData) => <DownloadItemModal type={ITEM_TYPES.RECORDING} url={rowData.url} />
  },
  {
    title: "",
    render: (rowData) =>
      userNotLimited() && (
        <DeleteItemModal
          type={ITEM_TYPES.RECORDING}
          id={rowData.scheduleid}
          title={rowData.schedtitle}
          callback={deleteItemCallback}
        />
      )
  }
]
