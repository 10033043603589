import { BASE_URL } from "../helpers/Constants"
import { getUserData, setUserData } from "../helpers/Helpers"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Button, Form, Grid, Segment, Loader } from "semantic-ui-react"

export const PASSWORD_RESET_HASH = "#frompasswordreset"

export default function ResetPassword() {
  const userData = getUserData() || {}
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const userID = query.get("userid")
  const resetKey = query.get("key")

  const [loginCheckDone, setLoginCheckDone] = useState(false)
  const [passwordResetMessage, setPasswordResetMessage] = useState()
  const [password1, setPassword1] = useState()
  const [password2, setPassword2] = useState()

  // eslint-disable-next-line
  useEffect(() => loginCheck(), [])

  function loginCheck() {
    console.log(`Entered loginCheck()`)

    const url = `${BASE_URL}admin/api/login-check`
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${userData.jwt}` }
      })
      .then(() => {
        window.location.href = "/"
      })
      .catch(() => {
        setLoginCheckDone(true)
        setUserData(null)
      })
  }

  function resetPassword() {
    console.log(`Entered resetPassword()`)

    if (validateInputs()) {
      setPasswordResetMessage(null)

      const url = `${BASE_URL}admin/api/password-reset`
      axios
        .post(url, {
          userid: userID,
          key: resetKey,
          password: password1
        })
        .then(() => {
          window.location.href = `/login${PASSWORD_RESET_HASH}`
        })
        .catch(() =>
          setPasswordResetMessage(
            <div className="error">Password reset failed, please try again.</div>
          )
        )
    }
  }

  function validateInputs() {
    let validationError = null
    validationError =
      password1 && password2 && password1.length < 8 ? (
        <div className="error">Password must be at least 8 characters long.</div>
      ) : (
        validationError
      )
    validationError =
      !validationError && password1 && password2 && password1 !== password2 ? (
        <div className="error">Password inputs do not match.</div>
      ) : (
        validationError
      )
    setPasswordResetMessage(validationError)

    return password1 && password2 && !validationError
  }

  const buildResetForm = () => (
    <Grid textAlign="center" style={{ height: "100vh" }}>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Segment stacked>
          <Form size="large">
            {passwordResetMessage}
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Enter new password"
              type="password"
              onChange={(_, data) => setPassword1(data.value)}
              required
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Confirm new password"
              type="password"
              onChange={(_, data) => setPassword2(data.value)}
              required
            />
            <Button color="blue" fluid size="large" onClick={resetPassword}>
              Reset Password
            </Button>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )

  return !loginCheckDone ? (
    <Loader active inline="centered" />
  ) : (
    <div style={{ marginTop: "50px" }}>
      <h1 style={{ textAlign: "center", marginBottom: "25px" }}>Reset MassLivestream Password</h1>
      {buildResetForm()}
    </div>
  )
}
