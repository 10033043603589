import { CopyToClipboardButton } from "../components/CopyToClipboardButton"
import { DeleteItemModal } from "../components/DeleteItemModal"
import {
  RECURRING_TYPE,
  ScheduleEventModal,
  SET_TYPE,
  SINGLE_TYPE
} from "../components/ScheduleEventModal"
import { COPY_TO_CLIPBOARD_TYPES } from "../helpers/Constants"
import { boolToYesNo, buildItemPermalinkURL, InfoBubble, userNotLimited } from "../helpers/Helpers"
import React from "react"

export const buildScheduleTableConfig = (
  siteInfo,
  setStatusCallback,
  deleteItemCallback,
  reloadScheduleCallback
) => {
  const tableColumns = [
    { title: "Title", field: "schedtitle", render: (rowData) => <b>{rowData.schedtitle}</b> },
    { title: "Start Time", field: "starttime" },
    { title: "End Time", field: "endtime" },
    {
      title: (
        <span>
          Stream(s)&nbsp;
          <InfoBubble size={"small"} message={"A list of the streams selected for the event."} />
        </span>
      ),
      field: "streamname"
    },
    {
      title: (
        <span>
          In Public Schedule&nbsp;
          <InfoBubble
            size={"small"}
            message={
              <div>
                <p>
                  Whether or not the event will be listed in the schedule on your parish's public
                  MassLivestream page.
                </p>
                <p>
                  Events with multiple streams will display a value for each corresponding to the
                  order in the "Stream(s)" column.
                </p>
              </div>
            }
          />
        </span>
      ),
      render: (rowData) => rowData.ispublic.map(boolToYesNo).join(", ")
    },
    {
      title: (
        <span>
          Record Stream&nbsp;
          <InfoBubble
            size={"small"}
            message={
              <div>
                <p>Whether or not the the stream will be recorded.</p>
                <p>
                  Events with multiple streams will display a value for each corresponding to the
                  order in the "Stream(s)" column.
                </p>
              </div>
            }
          />
        </span>
      ),
      render: (rowData) => rowData.dorecord.map(boolToYesNo).join(", ")
    },
    {
      title: (
        <span>
          Recording in Public Feed&nbsp;
          <InfoBubble
            size={"small"}
            message={
              <div>
                <p>
                  Whether or not the recording will be listed on your parish's public MassLivestream
                  page when completed.
                </p>
                <p>Shows as "NA" for streams not selected for recording.</p>
                <p>
                  Events with multiple streams will display a value for each corresponding to the
                  order in the "Stream(s)" column.
                </p>
              </div>
            }
          />
        </span>
      ),
      render: (rowData) =>
        rowData.recordingispublic
          .map((x, i) => (rowData.dorecord[i] ? boolToYesNo(x) : "NA"))
          .join(", ")
    },
    {
      title: "",
      render: (rowData) => (
        <CopyToClipboardButton
          type={COPY_TO_CLIPBOARD_TYPES.BUTTON}
          textToCopy={buildItemPermalinkURL(rowData.key)}
          typeOverride={"Permalink"}
        />
      )
    }
  ]

  if (userNotLimited()) {
    tableColumns.push({
      title: "Edit",
      render: (rowData) =>
        rowData.isongoing ? (
          <span>Cannot edit ongoing event</span>
        ) : (
          <div>
            <ScheduleEventModal
              siteInfo={siteInfo}
              editType={rowData.setid !== 0 ? SET_TYPE : SINGLE_TYPE}
              editID={rowData.setid !== 0 ? rowData.setid : rowData.scheduleid}
              setStatusCallback={setStatusCallback}
              reloadScheduleCallback={reloadScheduleCallback}
            />
            {rowData.seriesid !== 0 ? (
              <div style={{ marginTop: "10px" }}>
                <ScheduleEventModal
                  siteInfo={siteInfo}
                  editType={RECURRING_TYPE}
                  editID={rowData.seriesid}
                  setStatusCallback={setStatusCallback}
                  reloadScheduleCallback={reloadScheduleCallback}
                />
              </div>
            ) : null}
          </div>
        )
    })
    tableColumns.push({
      title: "Delete",
      render: (rowData) =>
        rowData.isongoing ? (
          <span>Cannot delete ongoing event</span>
        ) : (
          <div>
            <DeleteItemModal
              type={rowData.setid ? SET_TYPE : SINGLE_TYPE}
              id={rowData.setid || rowData.scheduledid}
              title={rowData.schedtitle}
              callback={deleteItemCallback}
            />
            {rowData.seriesid ? (
              <DeleteItemModal
                style={{ marginTop: "10px" }}
                type={RECURRING_TYPE}
                id={rowData.seriesid}
                title={rowData.schedtitle}
                callback={deleteItemCallback}
              />
            ) : null}
          </div>
        )
    })
  }

  return tableColumns
}
